import { Calendar as CalendarIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Calendar } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCustomer, getUserInfo, uploadFile } from "../../api";
import { FileUpload } from "../../components/Customers/file-upload";
import { FormField } from "../../components/Customers/form-field";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Switch } from "../../components/ui/switch";
import { getUserSchema } from "../../lib/schemas";
import { cn } from "../../lib/utils";
import { notification, userInfo } from "../../state/action";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

function AddCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [nationalCardImage, setNationalCardImage] = useState("");
  const [nationalCardBackImage, setNationalCardBackImage] = useState("");
  const [isForeign, setIsForeign] = useState(false);
  const nowDate = new Date();
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    engFname: "",
    engLname: "",
    address: "",
    zipCode: "",
    nationalCode: "",
    mobileNumber: "",
    birthDate: "",
    nationalCodeExpirationDate: ""
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("access_token")) {
      getUserInfo()
        .then((response) => {
          dispatch(userInfo(response.data.data));
        })
        .catch(() => {
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  const validate = () => {
    const schema = getUserSchema(isForeign);
    const validation = schema.safeParse(formData);
    if (!validation.success) {
      const newErrors = {};
      validation.error.errors.forEach((error) => {
        newErrors[error.path[0]] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const schema = getUserSchema(isForeign);
    const fieldValidation = schema.shape[name].safeParse(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validate()) return;

    setIsDisabled(true);
    const { fname, lname, engFname, engLname, nationalCodeExpirationDate, nationalCode, mobileNumber, zipCode, birthDate } = formData;
    const name = `${fname}|${lname}`;
    const engName = `${engFname}|${engLname}`;

    addCustomer(
      name,
      engName,
      formData.address,
      zipCode,
      nationalCode,
      nationalCodeExpirationDate,
      nationalCardImage,
      nationalCardBackImage,
      mobileNumber,
      isForeign,
      birthDate
    )
      .then(() => {
        dispatch(notification({ message: "مشتری با موفقیت افزوده شد", type: "suc", id: nowDate.getSeconds() }));
        navigate("/customers-list");
        setIsDisabled(false);
      })
      .catch((e) => {
        dispatch(notification({ message: e.response.data?.Error?.Message, type: "err", id: nowDate.getSeconds() }));
        setIsDisabled(false);
      });
  };

  const convertPersianToEnglishNumbers = useMemo(() => (str) => {
    const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    const englishNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    
    return str?.split('').map(c => {
      const index = persianNumbers.indexOf(c);
      return index !== -1 ? englishNumbers[index] : c;
    }).join('');
  }, []);
  

  const handleFileUpload = async (file, callback) => {
    const base64 = await convertBase64(file);
    const base64_pure = base64.split(";base64,")[1];

    uploadFile(base64_pure, file.name)
      .then((response) => {
        callback(response.data.data.fileAddress);
      })
      .catch((e) => {
        dispatch(notification({ message: e.response.data.Error.Message, type: "err", id: nowDate.getSeconds() }));
      });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const handleToggleChange = () => {
    setIsForeign(!isForeign);
    // setFormData({
    //   fname: "",
    //   lname: "",
    //   engFname: "",
    //   engLname: "",
    //   address: "",
    //   nationalCode: "",
    //   mobileNumber: "",
    //   birthDate: "",
    //   nationalCodeExpirationDate: "",
    //   zipCode: ""
    // });
    setErrors({});
  };

  return (
    <div>
      <Navbar displayMenu />
      <div className="w-full flex items-start">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="w-full p-8 max-md:p-4">
          <h2 className="text-xl font-bold text-gray-800 mb-12">افزودن مشتری</h2>
          <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
            <form onSubmit={onSubmit} className="flex flex-col items-start gap-y-4 max-w-4xl mx-auto">

              <div className="flex items-center gap-x-4 mb-4 w-full">
                <div className="max-md:col-span-1">
                  <Label className="inline-block mb-1 !mr-0" htmlFor="isForeign">
                    اتباع خارجی
                  </Label>
                </div>
                <div className="col-span-5">
                  <Switch className='!m-0' id="isForeign" onCheckedChange={handleToggleChange} checked={isForeign} />
                </div>
              </div>

              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="fname"
                  label="نام"
                  name="fname"
                  value={formData.fname}
                  onChange={handleInputChange}
                  error={errors.fname}
                  description="لطفاً نام کاربر را به فارسی وارد کنید"
                  required
                />

                <FormField
                  id="lname"
                  label="نام خانوادگی"
                  name="lname"
                  value={formData.lname}
                  onChange={handleInputChange}
                  error={errors.lname}
                  description="لطفاً نام خانوادگی کاربر را به فارسی وارد کنید"
                  required
                />
              </div>

              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="engFname"
                  label="نام انگلیسی"
                  name="engFname"
                  value={formData.engFname}
                  onChange={handleInputChange}
                  error={errors.engFname}
                  description="لطفاً نام کاربر را به انگلیسی وارد کنید"
                  required
                />

                <FormField
                  id="engLname"
                  label="نام خانوادگی انگلیسی"
                  name="engLname"
                  value={formData.engLname}
                  onChange={handleInputChange}
                  error={errors.engLname}
                  description="لطفاً نام خانوادگی کاربر را به انگلیسی وارد کنید"
                  required
                />
              </div>
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FormField
                  id="mobileNumber"
                  dir='auto'
                  inputMode="tel"
                  label="شماره موبایل"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  error={errors.mobileNumber}
                  description="لطفاً شماره موبایل کاربر را وارد کنید"
                  required
                />
                <FormField
                  id="zipCode"
                  dir='auto'
                  inputMode="numeric"
                  label="کدپستی"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  error={errors.zipCode}
                  description="کد پستی کاربر را وارد کنید"
                  required
                />
              </div>
              <FormField
                id="address"
                label="آدرس"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                error={errors.address}
                description="آدرس محل سکونت کاربر را وارد کنید"
                textarea
                required={isForeign}
              />
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                      <div className="flex items-center justify-between col-span-3">
                        <Label className="inline-block mb-1 !mr-0" htmlFor="birthDate">
                          تاریخ تولد
                        </Label>
                      </div>
                      <div className="col-span-3">
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "[&>span]:text-gray-800 !m-0 w-full justify-start text-left whitespace-normal font-normal",
                                !formData.birthDate && "text-muted-foreground"
                              )}
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {formData.birthDate ? formData.birthDate : <span>انتخاب</span>}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0 [& span]:!text-gray-800">
                            <Calendar onChange={(e) => {
                              setFormData(prev => ({
                                ...prev,
                                birthDate: convertPersianToEnglishNumbers(e.format("YYYY/MM/DD"))
                              }))}} name="birthDate" calendar={persian} locale={persian_fa} />
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                    <div className="w-full"></div>
              </div>
              <div className="flex max-md:flex-col items-start w-full gap-x-12">
                <FileUpload
                  id="id-card-front"
                  label={isForeign ? "تصویر کارت آمایش یا گذرنامه" : "تصویر کارت ملی"}
                  name="id-card-front"
                  onFileUpload={(file) => handleFileUpload(file, setNationalCardImage)}
                  uploadedImage={nationalCardImage}
                  description={isForeign ? "تصویر کارت آمایش یا گذرنامه کاربر را آپلود کنید" : "تصویر کارت ملی کاربر را آپلود کنید"}
                />

                <FileUpload
                  id="id-card-back"
                  label={isForeign ? "تصویر ویزا" : "تصویر پشت کارت ملی"}
                  name="id-card-back"
                  onFileUpload={(file) => handleFileUpload(file, setNationalCardBackImage)}
                  uploadedImage={nationalCardBackImage}
                  description={isForeign ? "تصویر ویزای کاربر را آپلود کنید" : "تصویر پشت کارت ملی کاربر را آپلود کنید"}
                />
              </div>

              <div className={cn(isForeign ? 'w-1/2' : 'w-full', 'flex max-md:flex-col items-start gap-x-12')}>
                {!isForeign && (
                  <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                    <div className="flex items-center justify-between col-span-3">
                      <Label className="inline-block mb-1 !mr-0" htmlFor="nationalCodeExpirationDate">
                        تاریخ انقضا کارت ملی
                      </Label>
                    </div>
                    <div className="col-span-3">
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "[&>span]:text-gray-800 !m-0 w-full justify-start text-left whitespace-normal font-normal",
                              !formData.nationalCodeExpirationDate && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {formData.nationalCodeExpirationDate ? formData.nationalCodeExpirationDate : <span>انتخاب</span>}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 [& span]:!text-gray-800">
                          <Calendar onChange={(e) => {
                            setFormData(prev => ({
                              ...prev,
                              nationalCodeExpirationDate: convertPersianToEnglishNumbers(e.format("YYYY/MM/DD"))
                            }))}} name="nationalCodeExpirationDate" calendar={persian} locale={persian_fa} />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                )}
                <FormField
                  id="nationalCode"
                  dir='auto'
                  inputMode="numeric"
                  label={isForeign ? "شماره فراگیر" : "شماره ملّی"}
                  name="nationalCode"
                  value={formData.nationalCode}
                  onChange={handleInputChange}
                  error={errors.nationalCode}
                  description={isForeign ? "شماره فراگیر کاربر را وارد کنید" : "کد ملّی کاربر را وارد کنید"}
                  required={isForeign ? false : true}
                />
              </div>
              <Button className='!m-0' type="submit" disabled={isDisabled}>
                ثبت اطلاعات
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;