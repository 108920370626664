import * as z from 'zod';

export const getUserSchema = (isForeign) =>
    z.object({
      fname: z.string().min(1, { message: "نام الزامی است" }),
      lname: z.string().min(1, { message: "نام خانوادگی الزامی است" }),
      engFname: z.string().min(1, { message: "نام انگلیسی الزامی است" }),
      engLname: z.string().min(1, { message: "نام خانوادگی انگلیسی الزامی است" }),
      address: isForeign ? z.string().min(1, { message: "آدرس الزامی است" }) : z.string().optional(),
      zipCode: z.string({ message: "کدپستی الزامی است" }).length(10, { message: "کدپستی باید ۱۰ رقم باشد" }),
      nationalCode: isForeign
        ? z.string().regex(/^\d{12}$/, { message: "شماره فراگیر باید 12 رقم و فقط شامل اعداد باشد" }).optional().or(z.literal(''))
        : z.string().regex(/^\d{10}$/, { message: "کد ملّی باید 10 رقم و فقط شامل اعداد باشد" }).length(10, { message: "کد ملّی باید دقیقاً 10 رقم باشد" }),
      mobileNumber: z.string(),
      birthDate: z.string({message: 'تاریخ تولد الزامی است'}),
    });
    